import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mcdo-booking-validate',
  standalone: true,
  imports: [TranslateModule, CommonModule, FormsModule],
  templateUrl: './validate.component.html',
  styleUrl: './validate.component.css'
})
export class ValidateComponent implements OnInit {

  legalConditions: string[];
  legalNoticeLink: string;

  @Input({ required: true })
  eventRecapTemplate: TemplateRef<void>;

  @Output()
  onConfirm = new EventEmitter();
  legalApproved: boolean;

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.stream("booking-form.confirmation.legal-conditions.legalNoticeLink").subscribe(legalNoticeLink => this.legalNoticeLink = legalNoticeLink);
    this.translate.stream("booking-form.confirmation.legal-conditions.conditions").subscribe(legalConditions => this.legalConditions = legalConditions);
  }
}
