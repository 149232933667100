<input *ngIf="onSearch" #search [(ngModel)]="value" class="search" type="text" placeholder="{{'customTable.search' | translate}}" (keydown.enter)="onSearch()">
<span class="text-grey">{{'customTable.results' | translate : '{from: ' + getFrom() + ', to: ' + getTo() + ', of: ' + getLength() + '}'}}</span>
<span class="right-aligned-elements">
  <span class="text-grey per-page">{{'customTable.perPage' | translate}}:&ensp;</span>
  <span class="resultsPerPage-switch">
    <select [(ngModel)]="perPage" (change)="changeResultsPerPage()" class="form-control">
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  </span>
  <csv-downloader [dataSource]="source" downloaderName="{{'customTable.export' | translate}}" [fileName]="fileExportName" [columns]="exportColumns" *hasRoles="['SERVICE_CENTER', 'FINANCIAL_USER']"></csv-downloader>
</span>

<ng-container *ngIf="source">
  <angular2-smart-table [settings]="settings" [source]="source" (rowSelect)="getPaging()"></angular2-smart-table>
</ng-container>
