import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalDataSource } from 'angular2-smart-table';
import { ExportColumnData } from './model';

@Component({
  selector: 'csv-downloader',
  templateUrl: './csv-downloader.component.html',
  styleUrls: ['./csv-downloader.component.scss']
})
export class CsvDownloaderComponent {
  @Input() downloaderName = 'Download CSV';
  @Input() columns: ExportColumnData[] = [];
  @Input() dataSource: LocalDataSource | undefined;
  @Input() data: any[] = [];
  @Input() fileName = 'data';
  @Output() onError = new EventEmitter<Error>();

  constructor(private renderer: Renderer2, private translate: TranslateService) {
  }

  download() {
    // If a datasource had been specified (Promise based), then we'll resolved it
    // and use its data
    if (this.dataSource) {
      this.dataSource.getFilteredAndSorted().then(result => {
        this.data = result;
        this.build();
      });
    } else {
      this.build();
    }
  }

  private build() {
    if (!this.data.length) {
      this.onError.emit(new Error('Data not available.'));
      return;
    }

    const csvString = this.construct();
    this.buildDownloader(csvString);
  }

  private getDocumentBody(): any {
    return document.body;
  }

  private construct(): string {
    let tabText = '\uFEFF';
    let keys = Object.keys(this.data[0]);

    if (this.columns && this.columns.length) {
      // In case we have a columns parameter, we want to order output in
      // the same order than keys in columns array
      keys = this.columns.map(c => c.key);
      const sourceKeys = Object.keys(this.data[0]);
      keys.filter(n => {
        return sourceKeys.indexOf(n) > -1;
      });
    }

    this.columns.forEach(h => {
      tabText += '"' + (h.titleTranslationKey ? this.translate.instant(h.titleTranslationKey) : h.defaultTitle) + '";';
    });

    if (tabText.length > 0) {
      tabText = tabText.slice(0, -1);
      tabText += '\r\n';
    }

    this.data.forEach(d => {
      keys.forEach(k => {
        if (!this.columns || this.columns.map(c => c.key).indexOf(k) > -1) {
          if (d.hasOwnProperty(k) && d[k] != null) {
            tabText += '"' + d[k] + '";';
          } else {
            tabText += '"N/A";';
          }
        }
      });

      tabText = tabText.slice(0, -1);
      tabText += '\r\n';
    });

    return tabText;
  }

  private buildDownloader(data: any) {
    const anchor = this.renderer.createElement('a');
    this.renderer.setStyle(anchor, 'visibility', 'hidden');
    this.renderer.setProperty(anchor, 'href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
    this.renderer.setProperty(anchor, 'target', '_blank');
    this.renderer.setProperty(anchor, 'download', this.computeFileName());
    this.renderer.appendChild(this.getDocumentBody(), anchor);
    setTimeout(() => {
      anchor.click();
      anchor.remove();
    }, 5);
  }

  private computeFileName() {
    return this.fileName + '_' + (new Date()).getTime() + '.csv';
  }
}
