<div class="input-group">
    <input
        type="text"
        class="form-control"
        [container]="isMobile ? 'body' : null"
        [placeholder]="datePlaceholder"
        [(ngModel)]="value"
        (blur)="onTouched()"
        ngbDatepicker
        #dp="ngbDatepicker"
    />
    <button class="btn btn-outline-light" (click)="dp.toggle()" type="button"><fa-icon [icon]="faCalendarAlt"></fa-icon></button>
</div>