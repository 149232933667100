<div class="modal-header">
    <h4 class="modal-title">{{ eventDetailOptions.event.title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss(EventDetailsActions.Close)"></button>
</div>
<div class="modal-body">
    <ng-container [ngTemplateOutlet]="eventDetailOptions.bookingDetail" [ngTemplateOutletContext]="{ $implicit: context }"></ng-container>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.close(EventDetailsActions.Cancel)">
        <i class="bi bi-trash"></i> {{ "calendar.event-details.cancel" | translate }}
    </button>
    <button type="button" class="btn btn-warning" (click)="activeModal.close(EventDetailsActions.Edit)">
        <i class="bi bi-pencil"></i> {{ "calendar.event-details.edit" | translate }}
    </button>
</div>