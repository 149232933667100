import { CommonModule } from '@angular/common';
import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapAdvancedMarker, MapInfoWindow } from '@angular/google-maps';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '../../data/store';

@Component({
  selector: 'mcdo-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css'],
  imports: [
    CommonModule, GoogleMapsModule
  ],
  standalone: true,
})
export class MapsComponent implements OnInit {

  @Input({required: true})
  allStores: Store[];
  
  @Input({required: true})
  mapOptions: google.maps.MapOptions;

  @Input()
  enableSearchBox: boolean = true;

  @Output()
  onStoreSelected = new EventEmitter<Store>();
  
  public openedInfoWindow: any;

  @ContentChild(TemplateRef) 
  infoWindowTemplate: TemplateRef<any>;

  @ViewChild("placeSearch")
  private placeSearchElement: ElementRef

  @ViewChild("map")
  private map: GoogleMap

  searchboxPlaceHolder: string;

  constructor(
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.translate.stream("common.map.searchbox-placeholder").subscribe(x => this.searchboxPlaceHolder = x);

    if (!this.mapOptions.mapId)
      this.mapOptions.mapId = "364cd4df970f344f";

    if (!this.mapOptions.disableDefaultUI !== false)
      this.mapOptions.disableDefaultUI = true;

    if (this.enableSearchBox) {
      await google.maps.importLibrary("places");

      const input = this.placeSearchElement.nativeElement as HTMLInputElement
      const searchBox = new google.maps.places.Autocomplete(input, {
        types: ["(regions)"],
        componentRestrictions: { country: "ch" }
      });
  
      searchBox.addListener("place_changed", () => {
        const place = searchBox.getPlace();
  
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.center = place.geometry.location;
          this.map.zoom = 17;
        }
      });
      
      this.map.controls[google.maps.ControlPosition.BLOCK_START_INLINE_START].push(input);
    }
  }

  public closeAndOpen(infoWindow: MapInfoWindow, marker: MapAdvancedMarker, store: Store) {
    this.openedInfoWindow?.close();
    infoWindow.open(marker);
    this.openedInfoWindow = infoWindow;
    this.onStoreSelected.emit(store);
  }

  createIcon(store: Store) {
    const icon = document.createElement('img');
    icon.src = store.matchesCriteria ? 'assets/map_green.png' : 'assets/map_disabled.png';
    icon.style.opacity = store.matchesCriteria ? '1' : '0.6';
    icon.style.zIndex = store.matchesCriteria ? '5' : '1';
    return icon;
  }
}
