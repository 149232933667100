import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalDataSource } from 'angular2-smart-table';
import { map } from 'rxjs/operators';
import { GlobalLanguageEventService } from '../../../core/service';

/*
* This component provides a smart table (angular2-smart-table) with new functionalities:
* - Plain text search field
* - CSV export
* Parameters:
* @settings -> Same settings as angular2-smart-table (columns settings)
* @data -> Same data as angular2-smart-table (table content)
* @tableColumnFiltered -> String array of field ID (not column title) on which plain text search will be computed.
* If @tableColumnFiltered is not passed, then search field will be hidden.
* @exportColumns -> Array of ExportColumnData describing which column should be exported and the translate key
* of its header.
*/
@Component({
  selector: 'custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit, OnChanges, AfterViewInit {

  public static KEY_PERPAGE_STORAGE = 'MCDONALD_DIGITAL_APP_PERPAGE';

  @Input() settings: any;
  @Input() data: any;
  @Input() fileExportName = 'data';
  @Input() tableColumnFiltered: any;
  @Input() exportColumns: any;
  @Input() sort: any;
  @Input() setSearch: any;
  @Output() getSearch = new EventEmitter();
  @Input() setPage: any;
  @Output() getPage = new EventEmitter();

  public source: LocalDataSource = new LocalDataSource();
  private oldSort: any;
  private length: number | undefined;
  public value = '';
  private page: number | undefined;
  public perPage = 10;

  constructor(private translate: TranslateService, private globalLanguageEventService: GlobalLanguageEventService) {
  }

  ngOnInit() {
    if (this.sort) {
      this.source.setSort([{field: this.sort, direction: 'asc'}]);
      this.oldSort = this.sort;
    }
    // Subscribe to LangEventService to udpate table headers on lang change
    this.setColumnHeaders();
    this.globalLanguageEventService.languageChanged.subscribe(item => {
      this.setColumnHeaders();
    });
    if (this.setSearch) {
      this.setSearch.subscribe((result: string) => {
        this.value = result;
        this.onSearch();
      });
    }
    if (this.setPage) {
      this.setPage.subscribe((result: string) => {
        this.page = parseInt(result, 10);
      });
    }
    if (sessionStorage.getItem(CustomTableComponent.KEY_PERPAGE_STORAGE) &&
      sessionStorage.getItem(CustomTableComponent.KEY_PERPAGE_STORAGE) !== 'null' &&
      sessionStorage.getItem(CustomTableComponent.KEY_PERPAGE_STORAGE) !== 'undefined') {
      const keyPerPage = sessionStorage.getItem(CustomTableComponent.KEY_PERPAGE_STORAGE);
      if (keyPerPage) {
        this.perPage = parseInt(keyPerPage, 10);
      }
      this.source.setPaging(this.source.getPaging().page, this.perPage);
    }
  }

  ngAfterViewInit() {
    this.source.setPaging(this.source.getPaging().page, this.perPage);
  }

  ngOnChanges() {
    if (this.sort !== this.oldSort) {
      this.source.setSort([{field: this.sort, direction: 'asc'}]);
      this.oldSort = this.sort;
    }
    this.source.load(this.data);
    this.source.getFilteredAndSorted().then((value: string) => this.length = value.length);
    if (this.page) {
      this.source.setPage(this.page);
    }
  }

  changeResultsPerPage() {
    this.source.setPaging(this.source.getPaging().page, this.perPage);
    sessionStorage.setItem(CustomTableComponent.KEY_PERPAGE_STORAGE, this.perPage.toString());
  }

  onSearch() {
    if (this.value !== '') {
      const filters: any[] = [];

      // Foreach field id of tableColumnFiltered we add to filters in order to use the created array
      // for the source data.
      for (const field of this.tableColumnFiltered) {
        filters.push({field: field, search: this.value.trim()});
      }

      this.source.setFilter(filters, false);
      this.getSearch.emit(this.value);
    } else {
      this.source.setFilter([]);
      this.getSearch.emit(this.value);
    }
    this.source.getFilteredAndSorted().then((value: string) => this.length = value.length);
  }

  getFrom() {
    if (this.data !== undefined && this.length !== undefined) {
      return (this.source.getPaging().page - 1) * this.source.getPaging().perPage + 1 < this.length ?
        (this.source.getPaging().page - 1) * this.source.getPaging().perPage + 1 : this.length;
    } else {
      return 0;
    }
  }

  getTo() {
    if (this.data !== undefined && this.length !== undefined) {
      return this.source.getPaging().page * this.source.getPaging().perPage < this.length ?
        this.source.getPaging().page * this.source.getPaging().perPage : this.length;
    } else {
      return 0;
    }
  }

  getLength() {
    if (this.length !== undefined) {
      return this.length;
    } else {
      return 0;
    }
  }

  getPaging() {
    this.page = this.source.getPaging().page;
    this.getPage.emit(this.source.getPaging().page);
  }

  private setTableSettings(colNames: string[]) {
    const cols = this.settings.columns;
    let i = 0;
    for (const key of Object.keys(cols)) {
      cols[key].title = colNames[i];
      i++;
    }
    // To trigger onChange event we need to change this.settings directly, not just sub-object
    this.settings = Object.assign({}, this.settings);
  }

  private setColumnHeaders() {
    const cols = this.settings.columns;
    const arr: string[] = [];
    for (const key of Object.keys(cols)) {
      if (cols[key].titleTranslationKey) {
        this.translate.get(cols[key].titleTranslationKey).pipe(map((label: string) => {
          arr.push(label);
        })).subscribe(() => {
          this.setTableSettings(arr);
        });
      }
    }
  }
}
