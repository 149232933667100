import { Injectable } from "@angular/core";
import { NgbDateStruct, NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class DatepickerI18N extends NgbDatepickerI18n {
    translations: {
        weekdays: string[],
        months: string[],
        weekLabel: string
    };

    constructor(private translate: TranslateService) {
        super();
        this.translate.stream("global.date").subscribe(
            (translations) => this.translations = translations
        );
    }

    getWeekdayLabel(weekday: number): string {
        return this.translations.weekdays[weekday - 1];
    }

    getMonthShortName(month: number): string {
        return this.translations.months[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}