import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter, map, merge } from 'rxjs';

@Directive({
  selector: '[isAuthenticated]',
  standalone: true
})
export class IsAuthenticatedDirective implements OnInit {

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private publicEventService: PublicEventsService,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>
  ) { }

  ngOnInit(): void {
    merge(
      this.oidcSecurityService.isAuthenticated(),
      this.publicEventService.registerForEvents()
        .pipe(
          filter(evt => evt.type === EventTypes.NewAuthenticationResult),
          map(evt => evt.value?.isAuthenticated as boolean)
        )
    ).subscribe(isAuthenticated => this.updateView(isAuthenticated));
  }

  updateView(isAuthenticated : boolean) {
    if (isAuthenticated) {
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }

}
