import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  template: `
    <a *ngIf="isVisible" [routerLink]="targetLink" routerLinkActive="active">
      <i class="fa fa-search action-icon" aria-hidden="true"></i>
    </a>
  `,
  styles: ['a {cursor: pointer}']
})
export class RouteLinkRenderComponent implements AfterContentInit {

  targetLink: string | undefined;
  isVisible: boolean = true;

  @Input() value: string | undefined;
  @Input() rowData: any;

  // value: string | undefined;
  // rowData: any;

  @Output() changesEmitter: EventEmitter<any> = new EventEmitter();

  // static componentInit(instance: RouteLinkRenderComponent, cell: Cell) {
  //   this.rowData = cell.getRow();
  //   this.value = cell.getValue().toUpperCase();
  // }

  ngAfterContentInit() {
    this.changesEmitter.emit();
  }

}
