import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../config/api-config';
import { Store } from '../data/store';
import { RestService } from './rest-service';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends RestService {
  constructor(
    private httpClient : HttpClient,
    apiConfig: ApiConfig
  ) {
    super(apiConfig.storeService);
  }

  getAll(filters?: { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> }): Observable<Store[]> {
    return this.httpClient.get<Store[]>(this.api('stores'), {params: filters})
  }

  getStore(id: number): Observable<Store> {
    return this.httpClient.get(this.api(`stores/${id}`));
  }
}
