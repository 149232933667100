import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class MailService {
  api = '/datasync-service/mails';

  constructor(private http: HttpClient) {}

  sendAmenitiesChangeRequestMail(message: string): Observable<any> {
    return this.sendMail(environment.endpoint + this.api + '/amenitiesChangeRequestMail', message);
  }

  sendHoursChangeRequestMail(message: string): Observable<any> {
    return this.sendMail(environment.endpoint + this.api + '/hoursChangeRequestMail', message);
  }

  sendBirthdayChangeRequestMail(message: string): Observable<any> {
    return this.sendMail(environment.endpoint + this.api + '/birthdayChangeRequestMail', message);
  }

  private sendMail(url: string, message: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http
      .post(url, message, {
        headers: headers
      });
  }

}
