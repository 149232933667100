<div class="row mb-1">
    <div class="col d-grid d-md-dlex justify-content-end">
        <button class="btn btn-primary" (click)="window.print()">{{'global.button.print' | translate}}</button>
    </div>
</div>

<form class="row justify-content-center align-items-end mb-3" [formGroup]="searchForm" (ngSubmit)="search()">
    <div class="col-sm-3 mb-1">
        <mcdo-store-select formControlName="store"></mcdo-store-select>
    </div>
    <div class="col-sm-2 mb-1">
        <label for="beginDate">{{ 'booking-list.search.dateStart' | translate }}</label>
        <div class="input-group">
            <input
                id="beginDate"
                class="form-control"
                [class.is-invalid]="dateBegin.invalid && (dateBegin.dirty || dateBegin.touched)"
                [placeholder]="datePlaceHolder"
                formControlName="dateBegin"
                ngbDatepicker
                #dpBegin="ngbDatepicker"
            />
            <button class="btn btn-outline-light" (click)="dpBegin.toggle()" type="button"><fa-icon [icon]="faCalendarAlt"></fa-icon></button>
        </div>
    </div>
    <div class="col-sm-2 mb-1">
        <label for="endDate">{{ 'booking-list.search.dateEnd' | translate }}</label>
        <div class="input-group">
            <input
                id="endDate"
                class="form-control"
                [class.is-invalid]="dateEnd.invalid && (dateEnd.dirty || dateEnd.touched)"
                [placeholder]="datePlaceHolder"
                formControlName="dateEnd"
                ngbDatepicker
                #dpEnd="ngbDatepicker"
            />
            <button class="btn btn-outline-light" (click)="dpEnd.toggle()" type="button"><fa-icon [icon]="faCalendarAlt"></fa-icon></button>
        </div>
    </div>
    <div class="col-md-1 mb-1">
        <button class="btn btn-primary" type="submit">{{ 'booking-list.search.button' | translate }}</button>
    </div>
</form>

<div class="row justify-content-center">
    <table class="table table-striped">
        <thead>
            <tr>
                @for (mapping of listMapping; track $index) {
                    <th>{{ mapping.label | translate }}</th>
                }
            </tr>
        </thead>
        <tbody>
            @for (event of events; track $index) {
                <tr>
                    @for (mapping of listMapping; track $index) {
                        @if (mapping.property) {
                            <td>{{ get(event, mapping.property) }}</td>
                        } @else {
                            <td>{{ mapping.converter(event) }}</td>
                        }
                    }
                </tr>
            }
        </tbody>
    </table>
</div>
