import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import { McDonaldsEvent } from '../../../data/mcdonalds-event';
import { Store } from '../../../data/store';
import { EventService } from '../../../services/event.service';
import { StoreService } from '../../../services/store.service';
import { DatepickerI18N } from '../../../utils/datepickerI18N';
import { DatepickerParserFormatter } from '../../../utils/datepickerParserFormatter';
import { StoreSelectComponent } from "../../store-select/store-select.component";
import { ListMapping } from './listMapping';

@Component({
  selector: 'mcdo-booking-list',
  standalone: true,
  imports: [TranslateModule, NgSelectModule, ReactiveFormsModule, NgbDatepickerModule, FontAwesomeModule, StoreSelectComponent],
  templateUrl: './list.component.html',
  styleUrl: './list.component.css',
  providers : [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDatepickerI18n, useClass: DatepickerI18N },
    { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter}
  ]
})
export class BookingListComponent implements OnInit {
  @Input()
  listMapping: ListMapping[]
  
  stores: Store[];
  events: any[];

  faCalendarAlt = faCalendarAlt;
  get = get;
  window = window;

  searchForm = this.formBuilder.group({
    store: this.formBuilder.control<Store>(null, { validators: Validators.required }),
    dateBegin: this.formBuilder.control<Date>(null, { validators: Validators.required }),
    dateEnd: this.formBuilder.control<Date>(null, Validators.required)
  });

  get store() {
    return this.searchForm.get("store");
  }
  
  get dateBegin() {
    return this.searchForm.get("dateBegin");
  }

  get dateEnd() {
    return this.searchForm.get("dateEnd");
  }
  
  datePlaceHolder: string;

  constructor(
    private storeService: StoreService,
    private bookingService: EventService<McDonaldsEvent>,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.storeService.getAll().subscribe(
      stores => this.stores = stores
    );
    this.translate.stream("global.date.placeholder").subscribe(
      placeholder => this.datePlaceHolder = placeholder
    )
  }

  search() {
    let values = this.searchForm.value;
    this.bookingService.findBookingBetween(
      values.store.storeIdNumber,
      values.dateBegin,
      values.dateEnd
    ).subscribe(
      events => this.events = events.sort((a, b) => a.date.getTime() - b.date.getTime())
    );
  }
}
