import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, Subject, catchError, finalize, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

export const isQuerying: Subject<Boolean> = new Subject();


export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {

  if (req.url.startsWith(environment.oauthEndpoint)) {
    return next(req);
  } else {
    isQuerying.next(true);

    let newHeaders = new HttpHeaders().set('X-Requested-With', 'XMLHttpRequest');
    req.headers.keys()?.forEach((headerKey) => {
      newHeaders = newHeaders.append(headerKey, req.headers.get(headerKey) as string);
    });

    let newReq = req.clone({headers: newHeaders});
    return next(newReq).pipe(finalize(() => isQuerying.next(false)));
  }
}

export function errorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        window.location.reload();
      }
      return throwError(() => new Error('HTTP error: ' + error.statusText + ' (' + error.status + ')'));
    })
  );
}
