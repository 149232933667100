import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class DatepickerParserFormatter extends NgbDateParserFormatter {

    constructor(private translate: TranslateService) {
        super();
    }

    override parse(value: string): NgbDateStruct {
        if (!value)
            return null;

        let datePart : string[];
        if (value.includes(".")) {
            datePart = value.split(".");
        } else {
            datePart = value.split("/");
        }

        return {
            day: Number.parseInt(datePart[0]),
            month: Number.parseInt(datePart[1]),
            year: Number.parseInt(datePart[2])
        }
    }

    override format(date: NgbDateStruct): string {
        if (!date) return "";

        return new Date(date.year, date.month - 1, date.day).toLocaleDateString(this.translate.currentLang, {day : '2-digit', month: '2-digit', year: 'numeric'});
    }
}