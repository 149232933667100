@if(context) {
    <div class="row justify-content-center">
        <div class="col-md-8">
        @if(!isCancelled) {
            <h1 class="text-center">{{'booking-cancellation.title' | translate}}</h1>
    
            <div>
                <ng-container [ngTemplateOutlet]="templateEventInfo" [ngTemplateOutletContext]="{ $implicit: context }"></ng-container>
            </div>
    
            <div class="alert alert-warning mt-4" role="alert">
                <strong>{{ 'booking-cancellation.alert.warning' | translate }}</strong>  {{ 'booking-cancellation.alert.message' | translate }}
            </div>
            <div>
                <button class="btn btn-primary" (click)="onCancelBtnClick()">{{ 'booking-cancellation.button.validate' | translate }}</button>
            </div>
        } @else {
            <div class="text-center">
                <div>
                    <i class="bi bi-check-circle"></i>
                    {{ 'booking-cancellation.validation.confirmation' | translate }}
                </div>
                <div>{{ 'booking-cancellation.validation.emailRecap' | translate }}</div>
            </div>
        }
        </div>
    </div>
}