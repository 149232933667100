import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class DatasyncService {
  api = '/datasync-service';

  constructor(private http: HttpClient) {}

  getAll(): Observable<any> {
    return this.http
      /* Could use pagination to load the data in the table on each page change instead of 1000 first elements.*/
      .get(environment.endpoint + this.api + '/datasyncreports?size=1000&sort=creationDate,desc');
  }

  getByStoreIdentifierAndType(storeIdentifier: number, type: string): Observable<any> {
    return this.http
      .get(environment.endpoint + this.api + '/datasyncreports/search/findByStoreIdentifierAndType?storeIdentifier='
        + storeIdentifier + '&type=' + type + '&sort=creationDate,desc&size=5');
  }

  get(id: number): Observable<any> {
    return this.http
      .get(environment.endpoint + this.api + '/datasyncreports/' + id);
  }

  getOutageFileURI() {
     return environment.endpoint + this.api + '/files/outage' + '?timeOffset=' + new Date().getTimezoneOffset();
  }

  getProductFileURI() {
     return environment.endpoint + this.api + '/files/product' + '?timeOffset=' + new Date().getTimezoneOffset();
  }

  getRFMPackageURI(id, status) {
    return environment.endpoint + this.api + '/files/rfmPackage/' + id +
      '?status=' + status + '&timeOffset=' + new Date().getTimezoneOffset();
  }

  getOutagePackageURI(id, status) {
    return environment.endpoint + this.api + '/files/outagePackage/' + id +
      '?status=' + status + '&timeOffset=' + new Date().getTimezoneOffset();
  }

}
