import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { clone, uniqWith } from 'lodash-es';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { AvailableBookingSlot } from '../../../../data/availableBookingSlot';
import { Store } from '../../../../data/store';
import { AvailableSlotService } from '../../../../services/available-slot.service';
import { InputDatepickerComponent } from "../../../input-datepicker/input-datepicker.component";
import { MapsComponent } from "../../../maps/maps.component";

@Component({
  selector: 'mcdo-booking-store-and-slot-select',
  standalone: true,
  imports: [InputDatepickerComponent, MapsComponent, CommonModule, FormsModule, ModalModule, TranslateModule, NgxSpinnerModule],
  templateUrl: './store-and-slot-select.component.html',
  styleUrl: './store-and-slot-select.component.css'
})
export class StoreAndSlotSelectComponent<T extends AvailableBookingSlot> {

  /** TODO: Remove after 2025/03/03 */
  newPartiesDate = new Date(2025, 2, 3);
  /** TODO: Remove after 2025/03/03 */

  private _store: Store;
  private _slot: T;

  @Input()
  isMobile: boolean;

  @Input({ required: true })
  stores: Store[];

  @Input({ required: true })
  storeTooltipTemplate: TemplateRef<any>;

  @Input()
  set selectedStore(store: Store) {
    this._store = store;
    this.selectedStoreChange.emit(this._store);
  }

  get selectedStore() {
    return this._store;
  }

  @Input()
  set selectedSlot(slot: T) {
    this._slot = slot;
    this.selectedSlotChange.emit(this._slot);
  }

  get selectedSlot() {
    return this._slot;
  }

  @Output()
  selectedStoreChange = new EventEmitter<Store>();

  @Output()
  selectedSlotChange = new EventEmitter<T>();

  @Output()
  onValidate = new EventEmitter();

  slots: T[] = [];
  flattenAndNotEmptySlots: T[] = [];

  get storeSlots() {
    return this.flattenAndNotEmptySlots.filter(x => x.storeIdentifier === this.selectedStore?.storeIdNumber);
  }

  dateSelected: Date;

  @ViewChild("mapAndSlotModal")
  modalMapAndSlots?: TemplateRef<any>;

  mapOptions: google.maps.MapOptions = {
    center: {
      lat: 46.826510,
      lng: 8.1
    },
    zoom: 8,
    clickableIcons: false,
    streetViewControl: false
  };

  constructor(
    private availableSlotSvc: AvailableSlotService<T>,
    private modalSvc: NgbModal,
    private spinnerSvc: NgxSpinnerService
  ) { }

  openMapAndSlotModal() {
    if (this.modalMapAndSlots) {
      this.modalSvc.open(this.modalMapAndSlots, { fullscreen: true });
    }
  }

  onDateChange(date: Date) {
    if (!date) return;

    /** TODO: Remove after 2025/03/03 */
    if (date >= this.newPartiesDate) return;
    /** TODO: Remove after 2025/03/03 */

    this.selectedStore = null;
    this.spinnerSvc.show();
    const storeIds = this.stores.map(x => x.storeIdNumber);
    this.availableSlotSvc.findAvailableBookingSlots(storeIds, date)
      .subscribe(slots => {
        this.slots = slots;
        this.flattenAndNotEmptySlots = slots.flatMap(slots => {
          const results: T[] = [];

          const uniqueSlotsStart = uniqWith(slots.timeSlotsStart, (a, b) => a.getTime() === b.getTime());

          for (const timeSlotStart of uniqueSlotsStart) {
            const flattenSlot = clone(slots);
            flattenSlot.timeSlotsStart = [timeSlotStart];
            results.push(flattenSlot as T);
          }
          return results;
        });

        // force store on map update
        const updatedStores: Store[] = [];
        for (const store of this.stores) {
          const slot = this.slots.find(x => x.storeIdentifier === store.storeIdNumber);

          if (slot) {
            store.matchesCriteria = slot.timeSlotsStart.length !== 0;
          } else {
            store.matchesCriteria = false;
          }

          updatedStores.push(store);
        }
        this.stores = updatedStores;
        this.spinnerSvc.hide();
      });
  }

  getStoreTooltipContext(store: Store): any {
    return {
      store: store,
      slots: this.slots.find(x => x.storeIdentifier === store.storeIdNumber)
    }
  }

  validate() {
    this.onValidate.emit();
  }

}
