import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { assign } from 'lodash-es';
import { map } from 'rxjs';
import { ApiConfig } from '../config/api-config';
import { AvailableBookingSlot } from '../data/availableBookingSlot';
import { RestService } from './rest-service';

@Injectable({
  providedIn: 'root'
})
export class AvailableSlotService<T extends AvailableBookingSlot> extends RestService {

  constructor(
    protected httpClient: HttpClient,
    apiConfig: ApiConfig
  ) {
    super(apiConfig.bookingService);
  }

  public findAvailableBookingBetween(storeIdNumber: number, begin: Date, end: Date) {
    const params = new HttpParams()
      .set('storeIdentifier', storeIdNumber)
      .set('dateStart', begin.toISOString().split('T')[0])
      .set('dateEnd', end.toISOString().split('T')[0]);

    return this.httpClient
      .get<T>(this.api('availablePartiesView'), { params })
      .pipe(map(x => assign(new AvailableBookingSlot(), x) as T));
  }

  findAvailableBookingSlots(storeIds: number[], date: Date) {
    const params = new HttpParams()
      .set('storeIdentifiers', storeIds.join(','))
      .set('date', date.toISOString().split("T")[0]);

    return this.httpClient
      .get<T[]>(this.api('availablePartiesView'), { params })
      .pipe(
        map(x => {
          const result: T[] = [];
          for (const iterator of x) {
            result.push(
              assign(new AvailableBookingSlot(), iterator) as T
            )
          }
          return result;
        })
      );
  }
}
