import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from './../component/message/message.interface';

@Injectable({providedIn: 'root'})
export class NotificationService {
    message: Subject<Message>;

    constructor() {
        this.message = new Subject<Message>();
    }

    success(detail: string): void {
        this.message.next({ value: detail, severity: 'success' });
    }

    info(detail: string): void {
        this.message.next({ value: detail, severity: 'info' });
    }

    warning(detail: string): void {
        this.message.next({ value: detail, severity: 'warning' });
    }

    error(detail: string): void {
        this.message.next({ value: detail, severity: 'error' });
    }

    clear(): void {
        this.message.next({ value: '' });
    }
}
