import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[integerInput]',
  standalone: true
})
export class IntegerInputDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    return /^(Backspace)|(Enter)|(Delete)|(ArrowLeft)|(ArrowRight)|[\d]+$/.test(event.key);
  }
}
