import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../config/api-config';
import { PriceSettings } from '../data/price-settings';
import { StoreBookingSettings } from '../data/storeBookingSettings';
import { RestService } from './rest-service';

@Injectable({
  providedIn: 'root'
})
export class StoreSettingsService<T extends StoreBookingSettings> extends RestService {

  constructor(
    protected httpClient: HttpClient,
    apiConfig: ApiConfig
  ) {
    super(apiConfig.bookingService);
  }

  public createSettings(settings : T) {
    return this.httpClient.post<T>(this.api('settings'), settings);
  }

  public updateSettings(settings: T) {
    return this.httpClient.put<T>(this.api(`settings/${settings.storeIdentifier}`), settings);
  }

  public getSettings(storeId: number) {
    const params = new HttpParams().set('storeIdentifier', storeId.toString());
    return this.httpClient.get<T>(this.api('settings/search/findByStoreIdentifier'), { params });
  }

  public getGlobalPriceSettings(): Observable<PriceSettings> {
    return this.httpClient.get<PriceSettings>(this.baseUrl +  'pricesettings');
  }
  
}
