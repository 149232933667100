import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { map, Observable } from 'rxjs';
import { ApiConfig } from '../config/api-config';
import { McDonaldsEvent } from '../data/mcdonalds-event';
import { toLocaleDateTimeString } from '../utils/toLocaleDateTimeString';
import { RestService } from './rest-service';

@Injectable({ providedIn: 'root' })
export class EventService<T extends McDonaldsEvent> extends RestService {
  constructor(
    protected httpClient: HttpClient,
    apiConfig: ApiConfig
  ) {
    super(apiConfig.bookingService);
  }

  public findWithMultipleStoresAndEventDateBetween(storeIds: number[], dateStart: Date, dateEnd: Date, loadConflicts = true): Observable<T[]> {
    const params = new HttpParams()
      .set('startDate', dateStart.toISOString())
      .set('endDate', dateEnd.toISOString())
      .set('storeIdentifiers', storeIds.join())
      .set('loadConflicts', loadConflicts);

    return this.httpClient.get<T[]>(this.api('parties/search/findByDateBetweenAndStoreIdentifierIn'), { params });
  }

  public findWithMultipleStoresAndBookingDateBetween(storeIds: number[], dateStart: Date, dateEnd: Date, loadConflicts = true): Observable<T[]> {
    const params = new HttpParams()
      .set('startDate', dateStart.toISOString())
      .set('endDate', dateEnd.toISOString())
      .set('storeIdentifiers', storeIds.join())
      .set('loadConflicts', loadConflicts);

    return this.httpClient.get<T[]>(this.api('parties/search/findByBookingDateBetweenAndStoreIdentifierIn'), { params });
  }
  
  public findBookingBetween(storeIdNumber: number, begin: Date, end: Date): Observable<T[]> {
    const params = new HttpParams()
      .set('storeIdentifier', storeIdNumber)
      .set('startDate', begin.toISOString())
      .set('endDate', end.toISOString());

    return this.httpClient.get<T[]>(this.api('parties/search/findByDateBetweenAndStoreIdentifier'), { params })
      .pipe(map(x => x.map(y => assign(new McDonaldsEvent(), y) as T)));
  }


  public cancelFrontOffice(link: string) {
    return this.httpClient.get(this.api(`parties/search/removeByLink?link=${link}`));
  }

  public cancelBackOffice(link: string) {
    return this.httpClient.get(this.api(`parties/search/removeFromGui?link=${link}`));
  }

  public getBooking(link: string) {
    return this.httpClient.get<T>(this.api(`parties/search/getByLink?link=${link}`))
      .pipe(map(y => assign(new McDonaldsEvent(), y) as T));
  }

  public updateBooking(booking: T) {
    return this.httpClient.put(
      this.api(`parties/${booking.id}`),
      booking
    );
  }

  public getByCardsStatus(sended: boolean) {
    const today = new Date()
    let lastMonth = new Date(today);
    lastMonth.setDate(today.getDate() - 30);

    let params = new HttpParams().set("sent", sended)

    if (sended) {
      params = params.set("updateDate", lastMonth.toISOString());
    }

    return this.httpClient.get<T[]>(this.api("parties/search/findByCardsSentAndCardsUpdateDate"), { params: params });
  }

  public findBookingAfter(storeId: number, date: Date) {
    const params = new HttpParams()
      .set('storeIdentifier', storeId)
      .set('startDate', date.toISOString());
      
    return this.httpClient.get<any>(this.api('parties/search/findByDateAfterAndStoreIdentifier'), { params });
  }

  saveParty(event: T) {
    if (!event.bookingDate)
      event.bookingDate = new Date();

    const tmpEvent = cloneDeep(event) as any;
    tmpEvent.date = toLocaleDateTimeString(event.date);
    tmpEvent.bookingDate = toLocaleDateTimeString(event.bookingDate);
    return this.httpClient.post<T>(this.api('booking/save'), tmpEvent);
  }

}
