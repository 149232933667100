import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class StoreService {
  api = '/store-service';

  constructor(private http: HttpClient) {}

  get(id: number): Observable<any> {
    return this.http
      .get(environment.endpoint + this.api + '/stores/' + id);
  }

  getAll(filters?: any): Observable<any> {
    return this.http
      .get(environment.endpoint + this.api + '/stores', {params: filters});
  }

}
