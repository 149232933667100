import { BookingChannel } from "../data/booking-channel";

export class RoleUtils {
    static roleToBookingChannel(role: string): BookingChannel.GUEST | BookingChannel.RESTAURANT | BookingChannel.TELEPERFORMANCE {
        switch (role) {
            case null:
                return BookingChannel.GUEST;
            case "RESTAURANT_HOST":
            case "RESTAURANT_MANAGER":
                return BookingChannel.RESTAURANT;
            default:
            case "ADMIN":
            case "TELEPERFORMANCE_OP":
            case "TELEPERFORMANCE_ADMIN":
                return BookingChannel.TELEPERFORMANCE;
        }
    }
}