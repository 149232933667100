import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ProductService {
  api_base = '/product-service';

  api_products = '/products';
  api_types = '/types';

  constructor(private http: HttpClient) {
  }

  getAll(language: string, search?: string, storeIdentifier?: number, equivalentCode?: number): Observable<any> {
    const filter = {language: language};
    if (search) {
      filter['search'] = search;
    }
    if (storeIdentifier) {
      filter['storeIdentifier'] = storeIdentifier;
    }
    if (equivalentCode) {
      filter['equivalentCode'] = equivalentCode;
    }
    return this.http
      .get(environment.endpoint + this.api_base + this.api_products,
        {
          params: filter
        });
  }

  get(code: number, language: string): Observable<any> {
    return this.http
      .get(environment.endpoint + this.api_base + this.api_products + '/' + code, {params: {language: language}});
  }

  getAllTypes(language: string) {
    return this.http
      .get(environment.endpoint + this.api_base + this.api_types, {params: {language: language}});
  }
}
