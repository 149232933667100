<div class="row">
    <div class="col-md-8 mx-auto">
        <div class="row mb-3">
            <ng-container [ngTemplateOutlet]="eventRecapTemplate"></ng-container>
        </div>
        
        <div class="row">
            <div class="col">
                <h5>{{ "booking-form.confirmation.legal-conditions.title" | translate }}</h5>
                <ul>
                    @for (legalCondition of legalConditions; track $index) {
                        <li>{{ legalCondition }}</li>
                    }
                </ul>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="approveLegals" [(ngModel)]="legalApproved">
                    <label>
                        {{ "booking-form.confirmation.legal-conditions.approve-legals" | translate }} <a target="_blank" [href]="legalNoticeLink">{{"booking-form.confirmation.legal-conditions.legalNoticeLabel" | translate}}</a>
                    </label>
                </div>
                <button class="btn btn-secondary" [disabled]="!legalApproved" (click)="onConfirm.emit()">
                    {{ "booking-form.confirmation.confirm-button" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>