@if(!isConfirmed) {
    <mat-stepper #stepper
        [orientation]="(isMobile | async) ? 'vertical' : 'horizontal'" 
        [linear]="true">
        <ng-template matStepperIcon="edit">
            <i class="bi bi-pencil"></i>
        </ng-template>
        <mat-step [completed]="selectedStore && selectedSlot">
            <ng-template matStepLabel>
                <i class="bi bi-calendar3 h1 text-primary"></i>
            </ng-template>
            <mcdo-booking-store-and-slot-select
                [isMobile]="(isMobile | async)"
                [storeTooltipTemplate]="storeTooltipTemplate"
                [(selectedStore)]="selectedStore"
                [(selectedSlot)]="selectedSlot"
                [stores]="stores"
                (onValidate)="stepper.next()"
                >
            </mcdo-booking-store-and-slot-select>
        </mat-step>
        <mat-step [completed]="false">
            <ng-template matStepLabel>
                <ng-container [ngTemplateOutlet]="secondStepLabelTemplate"></ng-container>
            </ng-template>
            <ng-container [ngTemplateOutlet]="recapStoreSlot"></ng-container>
            <ng-content></ng-content>
        </mat-step>
        <mat-step [completed]="false">
            <ng-template matStepLabel>
                <i class="bi bi-envelope-fill h1 text-primary"></i>
            </ng-template>
            <ng-container [ngTemplateOutlet]="recapStoreSlot"></ng-container>
            <mcdo-booking-contact-info 
                [addressRequired]="contactAddressRequired" 
                (onContactSubmit)="onContactSubmit($event)">
            </mcdo-booking-contact-info>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>
                <i class="bi bi-check-circle-fill h1 text-primary"></i>
            </ng-template>
            <mcdo-booking-validate
                [eventRecapTemplate]="eventRecapTemplate"
                (onConfirm)="onConfirm.emit()">
            </mcdo-booking-validate>
        </mat-step>
    </mat-stepper>
} @else {
    <mcdo-booking-confirmation [image]="confirmationImage"></mcdo-booking-confirmation>
}


<ng-template #storeTooltipTemplate let-params>
    <h5 class="bold">{{ params.store.publicName }}</h5>
    <div>
        <i class="fa fa-map-marker"></i>
        {{
        params.store.address.line1 +
        ' ' +
        params.store.address.line2 +
        ' ' +
        params.store.address.line3 +
        ' ' +
        params.store.address.city +
        ' ' +
        params.store.address.postalZip
        }}
    </div>
    <ng-container [ngTemplateOutlet]="storeTooltipExtendedTemplate" [ngTemplateOutletContext]="{ $implicit: params }"></ng-container>
</ng-template>

<ng-template #recapStoreSlot>
    @if(selectedStore && selectedSlot) {
        <div class="row text-center mb-3 mt-1">
            <table>
                <thead>
                    <tr>
                        <th scope="col" class="col-4">{{ "global.labels.store" | translate }}</th>
                        <th scope="col" class="col-4">{{ "global.labels.date" | translate }}</th>
                        <th scope="col" class="col-4">{{ "global.labels.timeSlot" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ selectedStore.publicName }}</td>
                        <td>{{ selectedSlot.timeSlotsStart[0] | date:'dd.MM.Y' }}</td>
                        <td>{{ selectedSlot.timeSlotsStart[0] | date:'HH:mm' }} - {{ selectedSlot.timeSlotsEnd[0] | date:'HH:mm' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
</ng-template>
