<div class="w-100 h-100" style="position: relative;">
  @if(enableSearchBox) {
    <input class="form-control m-3 searchbox" type="text" [placeholder]="searchboxPlaceHolder" #placeSearch>
  }
  <google-map [options]="mapOptions" width="100%" height="100%" #map>
    @for (store of allStores; track $index) {
      <map-advanced-marker
        [title]="store.publicName"
        [position]="{lat: store.geolocation.latitude, lng: store.geolocation.longitude}"
        [content]="createIcon(store)"
        (mapClick)="closeAndOpen(infoWindow, marker, store)"
        #marker="mapAdvancedMarker"
      >
        <map-info-window #infoWindow="mapInfoWindow">
          <ng-template [ngTemplateOutlet]="infoWindowTemplate" [ngTemplateOutletContext]="{$implicit: store}"></ng-template>
        </map-info-window>
      </map-advanced-marker>
    }
  </google-map>
</div>