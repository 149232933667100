export class AvailableBookingSlot {
    public storeIdentifier: number;
    public timeSlotsStart: Date[];
    public durationMinutes: number;
    public languages: string;

    public get timeSlotsEnd() {
        const dates: Date[] = []
        for (const timeSlotStart of this.timeSlotsStart) {
            const beginDate = timeSlotStart;
            const endDate = new Date(beginDate);
            endDate.setMinutes(beginDate.getMinutes() + this.durationMinutes);
            dates.push(endDate);
        }
        return dates;
    }
}