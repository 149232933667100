import { Conflict } from "./conflict";
import { Contact } from "./contact";

export class McDonaldsEvent {
  id?: number;
  storeIdentifier: number;
  date: Date;
  bookingDate: Date;
  durationMinutes: number;
  language: string;
  contact: Contact;
  role: string;
  comment: string;
  link: string;
  conflicts: Conflict[];
  deleted: boolean;
  removalSource: string;

  get endDate() {
    const endDate = new Date(this.date);
    endDate.setMinutes(this.date.getMinutes() + this.durationMinutes);
    return endDate;
  }
}