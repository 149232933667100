import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter, mergeMap } from 'rxjs';

@Directive({
  selector: '[hasRoles]',
  standalone: true
})
export class HasRolesDirective implements OnInit  {

  @Input({required: true})
  hasRoles: string[]

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private publicEventService: PublicEventsService,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>,
  ) { }

  ngOnInit(): void {
    this.publicEventService.registerForEvents()
      .pipe(
        filter(evt => evt.type === EventTypes.CheckingAuthFinished),
        mergeMap(x => this.oidcSecurityService.getUserData())
      )
      .subscribe(x => this.updateView(x))
  }

  updateView(userData: any) {
    if (!this.hasRoles) {
      this.viewContainer.createEmbeddedView(this.template);
      return;
    }

    for (const role of this.hasRoles) {
      if (userData?.roles?.includes(role)) {
        this.viewContainer.createEmbeddedView(this.template);
        return;
      }
    }

    this.viewContainer.clear();
  }
}
