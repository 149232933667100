import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AsYouType, isValidNumber } from 'libphonenumber-js';
import { Contact } from '../../../../data/contact';

@Component({
  selector: 'mcdo-booking-contact-info',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: './contact-info.component.html',
  styleUrl: './contact-info.component.css'
})
export class ContactInfoComponent implements OnChanges, OnInit {

  isSubmitted: boolean;

  @Input()
  addressRequired: boolean;

  @Output()
  onContactSubmit = new EventEmitter<Contact>();

  contactForm = this.fb.group({
    firstName: this.fb.control(null, {validators: [Validators.required]}),
    lastName: this.fb.control(null, {validators: [Validators.required]}),
    phone: this.fb.control(null, {validators: [Validators.required, this.phoneNumberValidator]}),
    email: this.fb.control(null, {validators: [Validators.email, Validators.required]}),
    emailConfirm: this.fb.control(null, {validators: [Validators.email, Validators.required]}),
    address: this.fb.group({
      street: this.fb.control(null),
      streetNum: this.fb.control(null),
      zipCode: this.fb.control(null),
      city: this.fb.control(null),
      country: this.fb.control(null)
    })
  }, { validators: this.emailConfirmedValidator });
  
  constructor(
    private fb : FormBuilder
  ) {}

  ngOnInit(): void {
    this.contactForm.controls.phone.valueChanges.subscribe(x => {
      this.contactForm.controls.phone.setValue(new AsYouType("CH").input(x), { emitEvent: false });
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const address = this.contactForm.controls.address;

    if (this.addressRequired) {
      for (const control of Object.values(address.controls)) {
        control.addValidators(Validators.required);
      }
    } else {
      for (const control of Object.values(address.controls)) {
        control.removeValidators(Validators.required);
      }
    }
  }

  private phoneNumberValidator(control: AbstractControl): ValidationErrors {
    if (control.touched) {
      return !isValidNumber(control.value, "CH") ? { "invalidPhoneNumber": control.value } : null;
    }

    return null;
  }

  private emailConfirmedValidator(group: AbstractControl): ValidationErrors {
    let email = group.get("email").value as string;
    let emailConfirm = group.get("emailConfirm").value as string;

    if (email !== emailConfirm) {
      return {
        "emailNotConfirmed": emailConfirm
      }
    }

    return null;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.contactForm.valid) {
      const contactVal = this.contactForm.value;
      let addressStr: string = null;

      if (this.addressRequired) {
        const address = contactVal.address;
        addressStr = `${address.streetNum} ${address.street}, ${address.zipCode} ${address.city}, ${address.country}`;
      }
      this.onContactSubmit.emit(
        {
          firstName: contactVal.firstName,
          lastName: contactVal.lastName,
          phoneNumber: contactVal.phone,
          email: contactVal.email,
          address: addressStr
        }
      );
    }
  }
}
