import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatepickerI18N } from '../../utils/datepickerI18N';
import { DatepickerParserFormatter } from '../../utils/datepickerParserFormatter';

@Component({
  selector: 'mcdo-input-datepicker',
  standalone: true,
  imports: [NgbDatepickerModule, TranslateModule, FontAwesomeModule, FormsModule],
  templateUrl: './input-datepicker.component.html',
  styleUrl: './input-datepicker.component.css',
  providers : [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDatepickerI18n, useClass: DatepickerI18N },
    { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter},
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => InputDatepickerComponent) }
  ]
})
export class InputDatepickerComponent implements OnInit, ControlValueAccessor {
  
  @Input()
  isMobile: boolean = false;

  private internalDate = new Date();

  get value(): Date {
    return this.internalDate;
  }

  set value(date : Date) {
    this.internalDate = date;
    this.onChange(date);
  }

  onChange = (value: Date) => {};
  onTouched = () => {};

  datePlaceholder: string;
  faCalendarAlt = faCalendarAlt;
  disabled: boolean;

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream("global.date.placeholder").subscribe(
      placeholder => this.datePlaceholder = placeholder
    )
  }

  writeValue(date: Date): void {
    this.value = date
  }

  registerOnChange(fn: (value: Date) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
